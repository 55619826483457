<template>
  <div class="col-12 d-flex align-items-center justify-content-center">
    <div class="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
      <div class="text-center text-md-center mb-4 mt-md-0">
        <img src="../assets/img/brand/logo_red.png" alt="logo" />
      </div>
      <div class="text-center text-md-center mb-4 mt-md-0">
        <h1 class="mb-0 h3">AS Holding d.o.o.</h1>
      </div>
      <form @submit.prevent="signIn" class="mt-4">
        <!-- Form -->
        <div class="form-group mb-4">
          <label for="email">{{ $t("login.username") }}</label>
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"><span class="fas fa-envelope"></span></span>
            <input type="email" class="form-control" placeholder="example@company.com" id="email" v-model.trim="emailAddress" autofocus required />
          </div>
        </div>
        <!-- End of Form -->
        <div class="form-group">
          <!-- Form -->
          <div class="form-group mb-4">
            <label for="password">{{ $t("login.password") }}</label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon2"><span class="fas fa-unlock-alt"></span></span>
              <input type="password" :placeholder="passwordPlaceholder" class="form-control" id="password" v-model.trim="password" required />
            </div>
          </div>
          <!-- End of Form -->
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="defaultCheck5" />
              <label class="form-check-label" for="defaultCheck5">
                {{ $t("login.rememberMe") }}
              </label>
            </div>
            <div><a href="#" class="small text-right">{{ $t("login.lostPassword") }}</a></div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="text-danger">{{ errorMessage }}</p>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-primary" :disabled="isLoading">
          <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true" v-if="isLoading"></span>
          {{ $t("login.signIn") }}
        </button>
      </form>
      <div class="mt-3 mb-4 text-center">
        <span class="font-weight-normal">Visit AS HOLDING d.o.o. on your social media accounts</span>
      </div>
      <div class="btn-wrapper my-4 text-center">
        <button class="btn btn-icon-only btn-pill btn-outline-light text-facebook mr-2" aria-label="facebook button" title="facebook button">
          <span aria-hidden="true" class="fab fa-facebook-f"></span>
        </button>
        <button class="btn btn-icon-only btn-pill btn-outline-light text-twitter mr-2" type="button" aria-label="twitter button" title="twitter button">
          <span aria-hidden="true" class="fab fa-twitter"></span>
        </button>
        <button class="btn btn-icon-only btn-pill btn-outline-light text-facebook" type="button" aria-label="github button" title="github button">
          <span aria-hidden="true" class="fab fa-github"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emailAddress: '',
      password: '',
      passwordPlaceholder: this.$t('login.passwordPlaceholder'),
      formIsValid: true,
      errorMessage: null,
      isLoading: false,
    };
  },
  created() {
    if (this.$store.getters.isAuth) {
      this.$router.push('/');
    }
  },
  methods: {
    async signIn() {
      this.isLoading = true;
      this.validateInput();

      try {
        const singInPayload = {
          emailAddress: this.emailAddress,
          password: this.password,
        };

        await this.$store.dispatch('singIn', singInPayload);
        this.isLoading = false;

        this.$router.replace('/admin/dashboard');
      } catch (error) {
        this.errorMessage = error.message || 'Unable to login, please contact support!';
        this.isLoading = false;
      }
    },
    validateInput() {
      if (this.emailAddress.length === 0 || this.password.length === 0) {
        this.formIsValid = false;
        this.errorMessage = 'Please provide email address and password to login!';
        this.isLoading = false;
        return;
      }
    },
  },
};
</script>
