<template>
  <div class="dropdown">
    <input v-if="Object.keys(selectedItem).length === 0" class="form-control" id="dropdown" ref="dropdowninput" type="text" @keyup="reloadDataOnSearch(query)" :placeholder="placeholderText" v-model.trim="query" />
    <input v-else @click="resetSelection" class="form-control" type="text" v-model.trim="selectedItem.name" />
    <div v-show="query && apiLoaded" class="dropdown-list">
      <div @click="selectItem(item)" v-show="itemVisible(item)" v-for="item in itemList" :key="item.name" class="dropdown-item">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        placeholderText: {
            type: String,
            default: 'Search'
        },
        callback: {
            type: Function
        },
        ajax: {
            type: Boolean,
            default: () => false,
        },
        data: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Object,
            default: () => {}
        },
    },
  data () {
    return {
      selectedItem: {},
      query: '',
      itemList: [],
      apiLoaded: false,
    }
  },
  mounted () {
    this.getList()
  },
  watch: {
      selected(newValue) {
          this.selectedItem = newValue;
      }
  },
  methods: {
    resetSelection () {
      this.selectedItem = {}
      this.$nextTick( () => this.$refs.dropdowninput.focus() )
      this.$emit('on-item-reset')
    },
    selectItem (theItem) {
      this.selectedItem = theItem 
      this.query = ''
      this.$emit('on-item-selected', theItem)
    },
    itemVisible (item) {
      let currentName = item.name.toLowerCase()
      let currentInput = this.query.toLowerCase()
      return currentName.includes(currentInput)
    },
    async getList () {
        if (!this.ajax) {
            this.itemList = this.data;
            this.apiLoaded = true;
        } else {
            await this.reloadDataOnSearch(this.query, 0, 10);
        }
    },
    async reloadDataOnSearch(searchQuery, pageIndex = 0, pageSize = 10) {
        this.apiLoaded = false;
        let response = await this.callback({ searchQuery, pageIndex, pageSize });
        this.itemList = response.data;
        this.apiLoaded = true;
    }
  }
}
</script>

<style scoped>
.dropdown{
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.dropdown-list{
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.dropdown-item{
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item:hover{
  background: #edf2f7;
}
.dropdown-item-flag{
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>