<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("groups.allGroups") }}</h2>
        <p class="mb-0">{{ $t("groups.allGroupDetail") }}</p>
      </div>
    </div>
    <data-table :callback="getGroupsData" ajax :columns="columns" :actions="actions"></data-table>
  </div>
</template>

<script>
import { productService } from '../services/product.service.js';

export default {
  data() {
    return {
      columns: [
        { name: 'code', th: this.$t('groups.code')  },
        { name: 'name', th: this.$t('groups.name') },
      ],
      actions: [
        {
          text: this.$t('groups.showProducts'),
          color: 'success',
          size: 'sm',
          action: (row) => {
            this.showProducts(row.code);
          },
        },
      ],
    };
  },
  methods: {
    showProducts(groupCode) {
      this.$router.push({ path: `groups/${groupCode}/products` });
    },
    async getGroupsData(params) {
      return await productService.getAllGroups(params);
    },
  },
};
</script>
