<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
    >
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">
          {{ $t("stocks.stocksFor") }}
          <span class="text-danger">{{ getLocationName }}</span>
        </h2>
        <p class="mb-0">{{ $t("stocks.allStocksDetail") }}</p>
      </div>
      <div class="left d-block mb-4 mb-md-0">
        <router-link
          to="/admin/locations"
          type="button"
          class="btn btn-secondary d-inline-flex align-items-center"
        >
          <span class="fas fa-arrow-left pr-2"></span>
          {{ $t("stocks.backToAllLocations") }}
        </router-link>
      </div>
    </div>
    <data-table :callback="getStockData" ajax :columns="columns"></data-table>
  </div>
</template>

<script>
import { locationService } from "../services/location.service.js";

export default {
  data() {
    return {
      locationCode: null,
      stocks: null,
      columns: [
        { name: "productCode", th: this.$t("stocks.productCode") },
        { name: "productName", th: this.$t("stocks.productName") },
        { name: "categoryName", th: this.$t("stocks.categoryName") },
        { name: "groupName", th: this.$t("stocks.groupName") },
        {
          name: "quantity",
          th: this.$t("stocks.quantity"),
          render(row) {
            return row.quantity.toFixed(2);
          },
        },
      ],
    };
  },
  computed: {
    getLocationName() {
      return this.stocks && this.stocks.data[0]?.locationName;
    },
  },
  methods: {
    async getStockData(params) {
      this.stocks = await locationService.getStockForLocation(
        this.locationCode,
        params
      );
      return this.stocks;
    },
  },
  created() {
    this.locationCode = this.$route.params.id;
  },
};
</script>
