<template>
  <div class="card border-light shadow-sm">
    <div class="card-body" v-if="!data.icon">
      <h2 class="h5">{{ data.name }}</h2>
      <h3 class="h2 mb-1">{{ data.value }}</h3>
      <div class="small mb-3">
        {{ data.description }}
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row d-block d-xl-flex align-items-center">
        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
          <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
            <span class="fas" :class="`fa-${data.icon}`"></span>
          </div>
          <div class="d-sm-none">
            <h2 class="h5">{{ data.name }}</h2>
            <h3 class="mb-1">{{ data.value }}</h3>
          </div>
        </div>
        <div class="col-12 col-xl-7 px-xl-0">
          <div class="d-none d-sm-block">
            <h2 class="h5">{{ data.name }}</h2>
            <h3 class="mb-1">{{ data.value | number }}</h3>
          </div>
          <small>{{ data.description }}</small>
          <div class="small mt-2" v-if="data.increaseValue">
            <span class="fas fa-angle-up text-success"></span>
            <span class="text-success font-weight-bold">{{ data.increaseValue }}</span> {{ data.increaseText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
};
</script>
