<template>
  <div
    class="col-12 text-center d-flex align-items-center justify-content-center"
  >
    <div>
      <img
        class="img-fluid w-75"
        src="../assets/img/illustrations/404.svg"
        alt="404 not found"
      />
      <h1 class="mt-5">
        {{ $t("pageNotFound.notFoundFirst") }} <span class="fw-bolder text-primary">{{ $t("pageNotFound.notFoundSecond") }}</span>
      </h1>
      <p class="lead my-4">
        {{ $t("pageNotFound.description") }}
      </p>
      <router-link
        to="/admin/dashboard"
        class="
          btn btn-gray-800
          d-inline-flex
          align-items-center
          justify-content-center
          mb-4
        "
      >
        {{ $t("pageNotFound.backToHome") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>