import apiClient from './api-client.js';

export const productService = {
  getAllProducts,
  getAllProductsByCategory,
  getAllProductsByGroup,
  getAllCategories,
  getAllGroups,
  getTotalPCG,
};

async function getAllProducts(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('products', { params });

  if (response.status !== 200) {
    throw Error('Unable to get product data!');
  }

  return response.data;
}

async function getAllProductsByCategory(categorCode, params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get(`categories/${categorCode}/products`, { params });

  if (response.status !== 200) {
    throw Error('Unable to get product data by category!');
  }

  return response.data;
}

async function getAllProductsByGroup(groupCode, params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get(`groups/${groupCode}/products`, { params });

  if (response.status !== 200) {
    throw Error('Unable to get product data by category!');
  }

  return response.data;
}

async function getAllCategories(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('products/categories', { params });

  if (response.status !== 200) {
    throw Error('Unable to get categories data!');
  }

  return response.data;
}

async function getAllGroups(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('products/groups', { params });

  if (response.status !== 200) {
    throw Error('Unable to get groups data!');
  }

  return response.data;
}

async function getTotalPCG() {
  let response = await apiClient.get('home/total-pcg');

  if (response.status !== 200) {
    throw Error('Unable to get product data!');
  }

  return response.data;
}
