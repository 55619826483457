import apiClient from './api-client.js';

export const locationService = {
  getAllLocations,
  getStockForLocation,
};

async function getAllLocations(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('locations', { params });

  if (response.status !== 200) {
    throw Error('Unable to get locations data!');
  }

  return response;
}

async function getStockForLocation(locationCode, params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get(`locations/${locationCode}/stocks`, { params });

  if (response.status !== 200) {
    throw Error('Unable to get stocks data!');
  }

  return response.data;
}
