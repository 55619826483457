<template>
  <footer class="footer section p-5">
    <div class="row">
      <div class="col-12 col-lg-6 mb-4 mb-lg-0">
        <p class="mb-0 text-center text-xl-left">
          Copyright © {{ date }}
          <a class="text-primary font-weight-normal" href="https://www.asholding.ba/" target="_blank">AS HOLDING d.o.o.</a>
        </p>
      </div>

      <div class="col-12 col-lg-6">
        <ul class="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
          <li class="list-inline-item px-0 px-sm-2">
            <a href="https://www.asholding.ba/o-nama/" target="_blank">{{ $t("footer.about") }}</a>
          </li>
          <li class="list-inline-item px-0 px-sm-2">
            <a href="https://www.asholding.ba/kako-biti-dio-tima/" target="_blank">{{ $t("footer.contact") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped></style>
