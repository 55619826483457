<script>
import { Bar, mixins } from "vue-chartjs";

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.chartLabels,
          datasets: [
            {
              type: "bar",
              label: "Vlastito učešće",
              borderColor: "rgb(248,189,122)",
              backgroundColor: "rgb(248,189,122)",
              data: this.chartData.data1,
            },
            {
              type: "bar",
              label: "Učešće ostalih",
              borderColor: "rgb(49,49,106)",
              backgroundColor: "rgb(49,49,106)",
              data: this.chartData.data2,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.renderLineChart();
  },
  watch: {
    chartData() {
      this.renderLineChart();
    },
  },
};
</script>
