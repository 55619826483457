<template>
  <div>
    <base-modal :showModal="showProfile" previewMode title="" @close="showUserProfile">
      <template #default>
        <h2 class="h5 mb-4">{{ $t("users.userPreview.generalInformation") }}</h2>
        <form>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div>
                <label for="firstName">{{ $t("users.userPreview.firstName") }}</label>
                <input class="form-control" id="firstName" type="text" placeholder="Enter your first name" v-model.trim="firstName" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div>
                <label for="lastName">{{ $t("users.userPreview.lastName") }}</label>
                <input class="form-control" id="lastName" type="text" placeholder="Also your last name" v-model.trim="lastName" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="emailAddress">{{ $t("users.userPreview.emailAddress") }}</label>
                <input class="form-control" id="emailAddress" type="email" placeholder="name@company.com" v-model.trim="emailAddress" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="department">{{ $t("users.userPreview.department") }}</label>
                <input class="form-control" id="department" type="text" v-model.trim="department" />
              </div>
            </div>
          </div>
        </form>
      </template>
    </base-modal>
    <nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark pl-0 pr-2 pb-0">
      <div class="container-fluid px-0">
        <div class="d-flex justify-content-between w-100" id="navbarSupportedContent">
          <div class="d-flex">
            <!-- Search form -->
            <!-- <form class="navbar-search form-inline" id="navbar-search-main">
              <div class="input-group input-group-merge search-bar">
                <span class="input-group-text" id="topbar-addon"><span class="fas fa-search"></span></span>
                <input type="text" class="form-control" id="topbarInputIconLeft" placeholder="Search" aria-label="Search" aria-describedby="topbar-addon" />
              </div>
            </form> -->
          </div>
          <ul class="navbar-nav align-items-center">
            <li class="nav-item dropdown">
              <a class="nav-link pt-1 px-0" :class="{ show: showUserMenu }" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" :aria-expanded="showUserMenu" @click="toggleUserMenu">
                <div class="media d-flex align-items-center">
                  <img class="user-avatar md-avatar rounded-circle" alt="Profile" src="../../assets/img/user_profile.png" />
                  <div class="media-body ml-2 text-dark align-items-center d-none d-lg-block">
                    <span class="mb-0 font-small font-weight-bold">{{ fullName }}</span>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dashboard-dropdown dropdown-menu-right mt-2" :class="{ show: showUserMenu }">
                <a class="dropdown-item font-weight-bold" @click="showUserProfile"><span class="far fa-user-circle"></span>{{ $t("global.myprofile") }}</a>
                <!-- <a class="dropdown-item font-weight-bold" href="#"><span class="fas fa-envelope-open-text"></span>Messages</a>
                <a class="dropdown-item font-weight-bold" href="#"><span class="fas fa-user-shield"></span>Support</a> -->
                <div role="separator" class="dropdown-divider"></div>
                <a class="dropdown-item font-weight-bold" @click="logout"><span class="fas fa-sign-out-alt text-danger"></span>{{ $t("global.logout") }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUserMenu: false,
      showProfile: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      department: '',
    };
  },
  computed: {
    fullName() {
      return this.firstName + ' ' + this.lastName;
    },
  },
  methods: {
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    showUserProfile() {
      this.showProfile = !this.showProfile;
      this.showUserMenu = false;
    },
    setUserData() {
      const user = this.$store.getters.getUser;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.emailAddress = user.emailAddress;
      this.department = user.department;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/auth');
    },
  },
  created() {
    this.setUserData();
  },
};
</script>
