<template>
  <div>
    <base-modal
      :showModal="show"
      previewMode
      :title="modalTitle"
      @close="showProduct"
    >
      <template #default>
        <h2 class="h5 mb-4">
          {{ $t("products.productPreview.generalInformation") }}
        </h2>
        <form id="modalForm">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div>
                <label for="code">{{
                  $t("products.productPreview.code")
                }}</label>
                <input
                  class="form-control"
                  id="code"
                  type="text"
                  v-model.trim="previewData.code"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div>
                <label for="name">{{
                  $t("products.productPreview.name")
                }}</label>
                <input
                  class="form-control"
                  id="name"
                  type="text"
                  v-model.trim="previewData.name"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="uom">{{ $t("products.productPreview.uom") }}</label>
                <input
                  class="form-control"
                  id="uom"
                  type="text"
                  v-model.trim="previewData.uom"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="barcode">{{
                  $t("products.productPreview.barcode")
                }}</label>
                <input
                  class="form-control"
                  id="barcode"
                  type="text"
                  v-model.trim="previewData.barcode"
                  readonly
                />
              </div>
            </div>
          </div>
        </form>
      </template>
    </base-modal>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
    >
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("products.allProducts") }}</h2>
        <p class="mb-0">{{ $t("products.allProductDetail") }}</p>
      </div>
      <div v-if="showButton" class="left d-block mb-4 mb-md-0">
        <router-link
          :to="goBackLink"
          type="button"
          class="btn btn-secondary d-inline-flex align-items-center"
        >
          <span class="fas fa-arrow-left pr-2"></span>
          {{ $t("products.goBack") }}
        </router-link>
      </div>
    </div>
    <data-table
      :callback="getProductData"
      ajax
      :columns="columns"
      :actions="actions"
    ></data-table>
  </div>
</template>

<script>
import { productService } from "../services/product.service.js";

export default {
  data() {
    return {
      show: false,
      code: null,
      previewData: {},
      columns: [
        { name: "code", th: this.$t("products.code") },
        { name: "name", th: this.$t("products.name") },
        { name: "uom", th: this.$t("products.uom") },
        { name: "barcode", th: this.$t("products.barcode") },
      ],
      actions: [
        // {
        //   text: this.$t('global.preview'),
        //   color: 'success',
        //   size: 'sm',
        //   action: (row) => {
        //     this.previewData = row;
        //     this.showProduct();
        //   },
        // },
      ],
    };
  },
  props: {
    option: {
      type: String,
      default: "Blank",
    },
  },
  computed: {
    modalTitle() {
      return this.previewData.name ? this.previewData.name.toUpperCase() : "";
    },
    showButton() {
      return this.option === "Category" || this.option === "Group";
    },
    goBackLink() {
      if (this.option === "Category") {
        return "/admin/categories";
      } else {
        return "/admin/groups";
      }
    },
  },
  methods: {
    showProduct() {
      this.show = !this.show;
    },
    async getProductData(params) {
      if (this.option === "Category") {
        return await productService.getAllProductsByCategory(this.code, params);
      } else if (this.option === "Group") {
        return await productService.getAllProductsByGroup(this.code, params);
      }
      return await productService.getAllProducts(params);
    },
  },
  created() {
    if (this.option !== "Blank") {
      this.code = this.$route.params.id;
    }
  },
};
</script>
