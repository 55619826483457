import apiClient from './api-client.js';

export const userService = {
  getAllUsers,
  getUserById,
  getUserRoles,
  createUser,
  updateUser,
  deleteUser,
};

async function getAllUsers(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('account/users', { params });

  if (response.status !== 200) {
    throw Error('Unable to get user data!');
  }

  return response.data;
}

async function getUserById() {}

async function getUserRoles() {
  let response = await apiClient.get('account/roles');

  if (response.status !== 200) {
    throw Error('Unable to get user roles!');
  }

  return response.data;
}

async function createUser(userData) {
  let response = await apiClient.post('account/user', userData);

  if (response.status !== 200) {
    throw Error('Unable to create user!');
  }

  return response.data;
}

async function updateUser(userData) {
  let response = await apiClient.put('account/user', userData);

  if (response.status !== 200) {
    throw Error('Unable to update user!');
  }

  return response.data;
}

async function deleteUser(userData) {
  let response = await apiClient.delete(`account/user/${userData.id}`);

  if (response.status !== 204) {
    throw Error('Unable to delete user!');
  }

  return response.data;
}
