<template>
  <div>
    <base-modal :showModal="show" previewMode :title="modalTitle" @close="showVendor">
      <template #default>
        <h2 class="h5 mb-4">{{ $t("vendors.vendorPreview.generalInformation") }}</h2>
        <form id="modalForm">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div>
                <label for="code">{{ $t("vendors.vendorPreview.code") }}</label>
                <input class="form-control" id="code" type="text" v-model.trim="previewData.code" readonly/>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div>
                <label for="name">{{ $t("vendors.vendorPreview.name") }}</label>
                <input class="form-control" id="name" type="text" v-model.trim="previewData.name" readonly/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="address">{{ $t("vendors.vendorPreview.address") }}</label>
                <input class="form-control" id="address" type="text" v-model.trim="previewData.address" readonly/>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="city">{{ $t("vendors.vendorPreview.city") }}</label>
                <input class="form-control" id="city" type="text" v-model.trim="previewData.city" readonly/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="contact">{{ $t("vendors.vendorPreview.contact") }}</label>
                <input class="form-control" id="contact" type="text" v-model.trim="previewData.contact" readonly/>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="phoneNumber">{{ $t("vendors.vendorPreview.phoneNumber") }}</label>
                <input class="form-control" id="phoneNumber" type="number" v-model.trim="previewData.phoneNumber" readonly/>
              </div>
            </div>
          </div>
        </form>
      </template>
    </base-modal>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("vendors.allVendors") }}</h2>
        <p class="mb-0">{{ $t("vendors.allVendorDetail") }}</p>
      </div>
    </div>
    <data-table :callback="getVendorData" ajax :columns="columns" :actions="actions"></data-table>
  </div>
</template>

<script>
import { vendorService } from '../services/vendor.service.js';

export default {
  data() {
    return {
      show: false,
      previewData: {},
      columns: [
        { name: 'code', th: this.$t('vendors.code') },
        { name: 'name', th: this.$t('vendors.name') },
        { name: 'address', th: this.$t('vendors.address') },
        { name: 'city', th: this.$t('vendors.city') },
        { name: 'contact', th: this.$t('vendors.contact') },
        { name: 'phoneNumber', th: this.$t('vendors.phoneNumber'), show: false },
      ],
      actions: [
        {
          text: this.$t('global.preview'),
          color: 'success',
          size: 'sm',
          action: (row) => {
            this.previewData = row;
            this.showVendor();
          },
        },
      ],
    };
  },
  computed: {
    modalTitle() {
      return this.previewData.name ? this.previewData.name.toUpperCase() : '';
    },
  },
  methods: {
    showVendor() {
      this.show = !this.show;
    },
    async getVendorData(params) {
      return await vendorService.getAllVendors(params);
    },
  },
};
</script>
