<template>
  <div>
    <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
      <router-link class="navbar-brand mr-lg-5" to="/admin/dashboard">
        <img class="navbar-brand-dark" src="../assets/img/brand/logo_horizontal_white.svg" alt="ASHolding logo" />
        <img class="navbar-brand-light" src="../assets/img/brand/logo_horizontal_white.svg" alt="ASHolding logo" />
      </router-link>
      <div class="d-flex align-items-center">
        <button
          class="navbar-toggler d-md-none"
          :class="{ collapsed: !menuOpen }"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          :aria-expanded="{ menuOpen }"
          aria-label="Toggle navigation"
          @click="toggleMenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
    <div class="container-fluid bg-soft">
      <div class="row">
        <div class="col-12">
          <the-sidebar></the-sidebar>
          <main class="content">
            <the-header></the-header>
            <router-view />
            <the-footer></the-footer>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from '../components/Sidebar/TheSidebar.vue';
import TheHeader from '../components/Header/TheHeader.vue';
import TheFooter from '../components/Footer/TheFooter.vue';

export default {
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  computed: {
    menuOpen() {
      return this.$store.getters.menuOpen;
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch('toggleMenu');
    },
  },
};
</script>
