<template>
  <nav id="sidebarMenu" class="sidebar d-md-block bg-primary text-white collapse" :class="{ show: menuOpen }" data-simplebar>
    <div class="sidebar-inner px-4 pt-3">
      <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
        <div class="d-flex align-items-center">
          <div class="user-avatar lg-avatar mr-4">
            <img src="../../assets/img/user_profile.png" class="card-img-top rounded-circle border-white" alt="profile picture" />
          </div>
          <div class="d-block">
            <h2 class="h6">Hi, {{ user.firstName }}</h2>
            <a class="btn btn-secondary text-dark btn-xs" @click="logout"
              ><span class="mr-2"><span class="fas fa-sign-out-alt"></span></span>Sign Out</a
            >
          </div>
        </div>
        <div class="collapse-close d-md-none">
          <a
            href="#sidebarMenu"
            class="fas fa-times"
            :class="{ collapsed: menuOpen }"
            data-toggle="collapse"
            data-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            :aria-expanded="menuOpen"
            aria-label="Toggle navigation"
            @click="toggleMenu"
          ></a>
        </div>
      </div>
      <router-link to="/admin/dashboard" class="navbar-brand d-flex justify-content-center" v-if="!menuOpen">
        <img class="navbar-brand-light" src="../../assets/img/brand/logo_horizontal_white.svg" alt="logo" />
      </router-link>
      <div role="separator" class="dropdown-divider mt-2 mb-2 border-black" v-if="!menuOpen"></div>
      <ul class="nav flex-column">
        <li class="nav-item" v-for="(item, index) in sectionOne" :key="item.id" :class="{ active: sectionOneIndex === index }" @click="sectionOneActive(index)">
          <router-link :to="item.link" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon"><span class="fas" :class="`fa-${item.icon}`"></span></span>
            <span class="mt-1">{{ item.title }}</span>
          </router-link>
        </li>
        <li role="separator" class="dropdown-divider mt-4 mb-3 border-black"></li>
        <li class="nav-item" v-for="(item, index) in sectionTwo" :key="item.id" :class="{ active: sectionTwoIndex === index }" @click="sectionTwoActive(index)">
          <router-link :to="item.link" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon"><span class="fas" :class="`fa-${item.icon}`"></span></span>
            <span class="mt-1">{{ item.title }}</span>
          </router-link>
        </li>
        <li role="separator" class="dropdown-divider mt-4 mb-3 border-black"></li>
        <li class="nav-item" v-for="(item, index) in sectionThree" :key="item.id" :class="{ active: sectionThreeIndex === index }" @click="sectionThreeActive(index)">
          <router-link :to="item.link" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon"><span class="fas" :class="`fa-${item.icon}`"></span></span>
            <span class="mt-1 small">{{ item.title }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      sectionOneIndex: 1,
      sectionTwoIndex: null,
      sectionThreeIndex: null,
      menuItems: this.$store.getters.menuItems,
      currentUser: this.$store.getters.getUser,
    };
  },
  computed: {
    sectionOne() {
      return this.menuItems.sectionOne;
    },
    sectionTwo() {
      return this.menuItems.sectionTwo.filter((item) => item.role.includes(this.currentUser.role));
    },
    sectionThree() {
      return this.menuItems.sectionThree.filter((item) => item.role.includes(this.currentUser.role));
    },
    menuOpen() {
      return this.$store.getters.menuOpen;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    sectionOneActive(index) {
      this.sectionOneIndex = index;
      this.sectionTwoIndex = null;
      this.sectionThreeIndex = null;
      if (this.menuOpen) this.$store.dispatch('toggleMenu');
    },
    sectionTwoActive(index) {
      this.sectionOneIndex = null;
      this.sectionTwoIndex = index;
      this.sectionThreeIndex = null;
      if (this.menuOpen) this.$store.dispatch('toggleMenu');
    },
    sectionThreeActive(index) {
      this.sectionOneIndex = null;
      this.sectionTwoIndex = null;
      this.sectionThreeIndex = index;
      if (this.menuOpen) this.$store.dispatch('toggleMenu');
    },
    toggleMenu() {
      this.$store.dispatch('toggleMenu');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/auth');
    },
  },
};
</script>
