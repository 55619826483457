import apiClient from './api-client.js';

export const vendorService = {
  getAllVendors,
};

async function getAllVendors(params = { pageIndex: 0, pageSize: 10 }) {
  let response = await apiClient.get('vendors', { params });

  if (response.status !== 200) {
    throw Error('Unable to get vendor data!');
  }

  return response.data;
}
