<template>
  <div>
    <div v-if="showModal" class="backdrop"></div>
    <div class="modal fade" :class="{ show: showModal }" id="modal-default" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true" aria-modal="true" :style="styleObject">
      <div class="modal-dialog modal-dialog-centered" :class="[size]" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="h6 modal-title">{{ title }}</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="tryClose">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="actions">
              <button type="submit" class="btn btn-pill btn-outline-success" form="modalForm" @click="save" v-if="!previewMode">
                {{ $t("global.save") }}
              </button>
              <button type="button" class="btn btn-pill btn-outline-danger" data-dismiss="modal" @click="tryClose">
                {{ $t("global.close") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close', 'save'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    size: {
      type: String,
      default: 'modal-lg',
    },
    title: {
      type: String,
      required: true,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    tryClose() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
  },
  computed: {
    styleObject() {
      return this.showModal ? { display: 'block' } : { display: 'none' };
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
</style>
