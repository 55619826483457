import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store/index.js';
import { Role } from '../helpers/role.js';

import Admin from '../layout/Admin.vue';
import Auth from '../layout/Auth.vue';

import Dashboard from '../views/Dashboard.vue';
import Users from '../views/Users.vue';
import Vendors from '../views/Vendors.vue';
import Products from '../views/Products.vue';
import Categories from '../views/Categories.vue';
import Groups from '../views/Groups.vue';
import Locations from '../views/Locations.vue';
import Stocks from '../views/Stocks.vue';
import SaleParticipation from '../views/reports/SaleParticipation.vue'
import SalePerCategory from '../views/reports/SalePerCategory.vue'
import SalePerGroup from '../views/reports/SalePerGroup.vue'
import Login from '../views/Login.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { authorize: [Role.Admin, Role.User] },
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
      },
      {
        path: '/admin/users',
        component: Users,
        meta: { authorize: [Role.Admin] },
      },
      {
        path: '/admin/vendors',
        component: Vendors,
        meta: { authorize: [Role.Admin] },
      },
      {
        path: '/admin/products',
        component: Products,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/categories/:id/products',
        component: Products,
        props: { option: "Category" },
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/groups/:id/products',
        component: Products,
        props: { option: "Group" },
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/categories',
        component: Categories,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/groups',
        component: Groups,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/locations',
        component: Locations,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/locations/:id/stocks',
        component: Stocks,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/reports/sale-participation',
        component: SaleParticipation,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/reports/sale-category',
        component: SalePerCategory,
        meta: { authorize: [Role.Admin, Role.User] },
      },
      {
        path: '/admin/reports/sale-group',
        component: SalePerGroup,
        meta: { authorize: [Role.Admin, Role.User] },
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: Auth,
    children: [
      {
        path: '/auth/login',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '*',
    component: NotFound,
  }
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = store.getters.getUser;

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/auth/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' });
    }
  }

  next();
});

export default router;
