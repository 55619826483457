<template>
  <div>
    <base-modal :showModal="showProfile" title="" @close="showUserProfile">
      <template #default>
        <h2 class="h5 mb-4">{{ $t("users.userPreview.generalInformation") }}</h2>
        <form id="modalForm" @submit.prevent="saveUser(formData)">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="firstName">{{ $t("users.userPreview.firstName") }}</label>
                <input class="form-control" id="firstName" type="text" placeholder="Enter your first name" v-model.trim="formData.firstName" required />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="lastName">{{ $t("users.userPreview.lastName") }}</label>
                <input class="form-control" id="lastName" type="text" placeholder="Also your last name" v-model.trim="formData.lastName" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="emailAddress">{{ $t("users.userPreview.emailAddress") }}</label>
                <input class="form-control" id="emailAddress" type="email" placeholder="name@company.com" v-model.trim="formData.emailAddress" required />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="password">{{ $t("users.userPreview.password") }}</label>
                <input class="form-control" id="password" type="text" v-model.trim="formData.password" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="role">{{ $t("users.userPreview.role") }}</label>
              <select class="form-select mb-0" id="role" aria-label="Select member role" v-model="formData.role" required>
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.title }}
                </option>
              </select>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="department">{{ $t("users.userPreview.department") }}</label>
                <input class="form-control" id="department" type="text" v-model.trim="formData.department" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="vendor">{{ $t("users.userPreview.vendor") }}</label>
              <drop-down placeholderText="Search for vendor" ajax :callback="getVendors" :selected="vendor" @on-item-selected="selectVendor">
              </drop-down>
            </div>
            <div class="col-md-6 mb-3">
            </div>
          </div>
        </form>
      </template>
    </base-modal>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("users.allUsers") }}</h2>
        <p class="mb-0">{{ $t("users.allUsersDetail") }}</p>
      </div>
      <div class="d-block mb-4 mb-md-0">
        <button class="btn btn-primary" type="button" @click="createUser"><span class="fas fa-user-plus mr-2"></span>{{ $t("users.addUser") }}</button>
      </div>
    </div>
    <data-table :callback="getUsers" ajax :key="reload" :columns="columns" :actions="actions"></data-table>
  </div>
</template>

<script>
import { userService } from '../services/user.service.js';
import { vendorService } from '../services/vendor.service.js';

export default {
  data() {
    return {
      showProfile: false,
      id: '',
      formData: {},
      reload: false,
      roles: [],
      vendor: {},
      columns: [
        { name: 'id', th: 'Id', show: false },
        { name: 'firstName', th: this.$t('users.table.firstName') },
        { name: 'lastName', th: this.$t('users.table.lastName') },
        { name: 'emailAddress', th: this.$t('users.table.emailAddress') },
        { name: 'department', th: this.$t('users.table.department') },
        { name: 'vendorName', th: this.$t('users.table.vendor') },
      ],
      actions: [
        {
          text: this.$t('global.preview'),
          color: 'success',
          size: 'sm',
          action: (row) => {
            this.formData = row;
            this.vendor = {
              id: this.formData.vendorNo,
              name: this.formData.vendorName
            }
            this.showUserProfile();
          },
        },
        {
          text: this.$t('global.delete'),
          color: 'danger',
          size: 'sm',
          action: (row) => {
            this.deleteUser(row)
          },
        },
      ],
    };
  },
  methods: {
    showUserProfile() {
      this.showProfile = !this.showProfile;
    },
    createUser() {
      this.formData = {},
      this.showProfile = !this.showProfile;
    },
    selectVendor(vendorData) {
      this.formData.vendorNo = vendorData.code;
      this.formData.vendorName = vendorData.name;
    },
    async reloadData() {
      await this.getUsers();
      this.reload = !this.reload;
    },
    async saveUser(data) {
      if (data.id) {
        await userService.updateUser(data);
      } else {
        await userService.createUser(data);
      }
      this.showProfile = !this.showProfile;
      this.reloadData();
    },
    async getUsers(params) {
      return await userService.getAllUsers(params);
    },
    async deleteUser(data) {
      if (data.id) {
        await userService.deleteUser(data);
        this.reloadData();
      }
    },
    async getVendors(params) {
      return await vendorService.getAllVendors(params);
    },
  },
  async created() {
    this.roles = await userService.getUserRoles();
  },
};
</script>
