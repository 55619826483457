<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gradient: null,
    };
  },
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.chartLabels,
          datasets: [
            {
              label: "Total",
              borderColor: "#373d3f",
              pointBackgroundColor: "white",
              borderWidth: 5,
              pointBorderColor: "white",
              backgroundColor: this.gradient,
              data: this.chartData,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(49,49,106, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(49,49,106, 0.25)");
    this.gradient.addColorStop(1, "rgba(49,49,106, 0)");

    this.renderLineChart();
  },

  watch: {
    chartData() {
      this.renderLineChart();
    },
  },
};
</script>
