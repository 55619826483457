<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="loading"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ $t("global.loading") }}...</span>
      </div>
    </div>
    <div :style="{ opacity: loading ? 0.2 : 1 }">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
      ></div>
      <div class="row justify-content-md-center">
        <div class="col-12 mb-4">
          <div class="card bg-yellow-alt shadow-sm">
            <div class="card-header d-flex flex-row align-items-center flex-0">
              <div class="d-block">
                <div class="h5 font-weight-normal mb-2">
                  {{ $t("dashboard.sale") }}
                </div>
                <h2 class="h3">{{ todayTotal | toCurrency }}</h2>
                <div class="small mt-2">
                  <span class="font-weight-bold mr-2" v-if="yearView">{{
                    $t("dashboard.lastMonth")
                  }}</span>
                  <span class="font-weight-bold mr-2" v-else>{{
                    $t("dashboard.yesterday")
                  }}</span>
                  <span
                    class="fas mr-1"
                    :class="[
                      percentage < 0
                        ? 'text-success fa-angle-up'
                        : 'text-danger fa-angle-down',
                    ]"
                  ></span>
                  <span
                    class="font-weight-bold"
                    :class="[percentage < 0 ? 'text-success' : 'text-danger']"
                    >{{ Math.abs(percentage) }}%</span
                  >
                </div>
              </div>
              <div class="d-flex ml-auto">
                <a
                  href="#"
                  class="btn btn-tertiary text-dark btn-sm mr-2"
                  @click="updateDays(daysFromStartOfYear, true)"
                  >{{ $t("dashboard.year") }}</a
                >
                <a
                  href="#"
                  class="btn btn-secondary text-dark btn-sm mr-2"
                  @click="updateDays(30, false)"
                  >{{ $t("dashboard.month") }}</a
                >
                <a
                  href="#"
                  class="btn btn-primary btn-sm mr-3"
                  @click="updateDays(7, false)"
                  >{{ $t("dashboard.week") }}</a
                >
              </div>
            </div>
            <div class="card-body p-4">
              <area-chart
                v-if="data.length > 0"
                :chartData="data"
                :chartLabels="labels"
              ></area-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div
          v-for="widget in widgetsData"
          :key="widget.name"
          class="col-12 col-sm-6 col-xl-4 mb-4"
        >
          <base-widget :data="widget"></base-widget>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-header border-bottom border-light">
              <h2 class="h5 mb-0">
                {{ $t("dashboard.top10Products") }}
                <span class="text-gray-600 font-small"
                  >({{ $t("dashboard.last7days") }})</span
                >
              </h2>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-centered table-nowrap mb-0 rounded">
                  <thead class="thead-light">
                    <tr>
                      <th class="border-0">#</th>
                      <th class="border-0">{{ $t("dashboard.name") }}</th>
                      <th class="border-0">{{ $t("dashboard.total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in topPCG.products"
                      :key="item.code"
                    >
                      <td>
                        <a href="#" class="text-primary font-weight-bold">{{
                          ++index
                        }}</a>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.total | toCurrency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-header border-bottom border-light">
              <h2 class="h5 mb-0">
                {{ $t("dashboard.top10Categories") }}
                <span class="text-gray-600 font-small"
                  >({{ $t("dashboard.last7days") }})</span
                >
              </h2>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-centered table-nowrap mb-0 rounded">
                  <thead class="thead-light">
                    <tr>
                      <th class="border-0">#</th>
                      <th class="border-0">{{ $t("dashboard.name") }}</th>
                      <th class="border-0">{{ $t("dashboard.total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in topPCG.categories"
                      :key="item.code"
                    >
                      <td>
                        <a href="#" class="text-primary font-weight-bold">{{
                          ++index
                        }}</a>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.total | toCurrency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-header border-bottom border-light">
              <h2 class="h5 mb-0">
                {{ $t("dashboard.top10Groups") }}
                <span class="text-gray-600 font-small"
                  >({{ $t("dashboard.last7days") }})</span
                >
              </h2>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-centered table-nowrap mb-0 rounded">
                  <thead class="thead-light">
                    <tr>
                      <th class="border-0">#</th>
                      <th class="border-0">{{ $t("dashboard.name") }}</th>
                      <th class="border-0">{{ $t("dashboard.total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in topPCG.groups" :key="item.code">
                      <td>
                        <a href="#" class="text-primary font-weight-bold">{{
                          ++index
                        }}</a>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.total | toCurrency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-12 col-sm-6 col-xl-4 mb-4 fmxh-500">
          <div class="card border-light shadow-sm">
            <div class="card-header border-bottom border-light">
              <h2 class="h5 mb-0">
                {{ $t("dashboard.topLocationsPerSale") }}
                <span class="text-gray-600 font-small"
                  >({{ $t("dashboard.fromBeginingOfTheMonth") }})</span
                >
              </h2>
            </div>
            <div class="card-body" style="max-height: 400px; overflow-y: auto">
              <div
                v-for="location in totalByLocations"
                :key="location.code"
                class="row align-items-center mb-4"
              >
                <div class="progress-wrapper">
                  <div class="progress-info">
                    <div class="h6 mb-0">
                      {{ location.name }}
                      <span class="text-gray-600 font-small"
                        >({{ location.total | toCurrency }})</span
                      >
                    </div>
                    <div class="small fw-bold">
                      <span>{{ locationPercentage(location) | number }} %</span>
                    </div>
                  </div>
                  <div class="progress mb-0">
                    <div
                      class="progress-bar bg-dark"
                      role="progressbar"
                      aria-valuenow="34"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: locationPercentage(location) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4">
          <div class="card border-light shadow-sm">
            <div class="card-header border-bottom border-light">
              <h2 class="h5 mb-0">
                {{ $t("dashboard.saleAndStock") }}
              </h2>
            </div>
            <div class="card-body">
              <div class="d-block">
                <div class="d-flex align-items-center me-5">
                  <div
                    class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0 px-2"
                  >
                    <span class="fas fa-coins"></span>
                  </div>
                  <div class="d-block px-2">
                    <label class="mb-0">{{
                      $t("dashboard.stockValueYear")
                    }}</label>
                    <h4 class="mb-0">
                      {{ totalStockValue.data | toCurrency }}
                    </h4>
                  </div>
                </div>
                <div class="d-flex align-items-center pt-3">
                  <div
                    class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0 px-2"
                  >
                    <span class="fas fa-file-invoice-dollar"></span>
                  </div>
                  <div class="d-block px-2">
                    <label class="mb-0">{{
                      $t("dashboard.saleValueYear")
                    }}</label>
                    <h4 class="mb-0">{{ totalValue | toCurrency }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 mb-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaChart from "../components/Charts/AreaChart.vue";
import { productService } from "../services/product.service.js";
import { saleService } from "../services/sale.service.js";

export default {
  components: { AreaChart },
  data() {
    return {
      widgetsData: [
        {
          icon: "boxes",
          code: "Products",
          name: this.$t("dashboard.products"),
          value: this.numberOfProducts,
          description: this.$t("dashboard.totalNumberOfProducts"),
        },
        {
          icon: "list",
          code: "Categories",
          name: this.$t("dashboard.categories"),
          value: this.numberOfCategories,
          description: this.$t("dashboard.totalNumberOfCatgeories"),
        },
        {
          icon: "layer-group",
          code: "Groups",
          name: this.$t("dashboard.groups"),
          value: this.numberOfGroups,
          description: this.$t("dashboard.totalNumberOfGroups"),
        },
      ],
      totalPCG: {},
      topPCG: {},
      totalByDate: [],
      totalByLocations: [],
      totalAllLocations: 0,
      chartData: [],
      chartLabels: [],
      todayTotal: 0,
      yesterdayTotal: 0,
      loading: true,
      days: 7,
      months: this.$t("monthNames"),
      yearView: false,
      totalValue: 0.0,
      totalStockValue: 0.0,
    };
  },
  computed: {
    data() {
      return this.chartData;
    },
    labels() {
      return this.chartLabels;
    },
    percentage() {
      let diff = this.yesterdayTotal - this.todayTotal;
      let percentage = ((diff / this.yesterdayTotal) * 100).toFixed(2);
      return percentage;
    },
    daysFromStartOfYear() {
      var currentDate = new Date().getTime();
      var currentYear = new Date(new Date().getFullYear(), 0, 1);

      return Math.ceil((currentDate - currentYear + 1) / 86400000);
    },
  },
  methods: {
    updateWidgetData(data) {
      let objIndex = this.widgetsData.findIndex((w) => w.code === "Products");
      this.widgetsData[objIndex].value = data.numberOfProducts;

      objIndex = this.widgetsData.findIndex((w) => w.code === "Categories");
      this.widgetsData[objIndex].value = data.numberOfCategories;

      objIndex = this.widgetsData.findIndex((w) => w.code === "Groups");
      this.widgetsData[objIndex].value = data.numberOfGroups;
    },
    updateDays(numberOfDays, yearView) {
      this.days = numberOfDays;
      this.getChartProps(this.totalByDate, yearView);
    },
    getChartProps(data, yearView) {
      this.yearView = yearView;
      if (this.yearView) {
        data = this.groupData(data);
        this.chartData = data.map((d) => d.total).slice(0, data.length);
        this.chartLabels = data
          .map((d) => this.months[d.month - 1])
          .slice(0, data.length);
        this.todayTotal = data
          .map((d) => d.total)
          .reverse()
          .slice(0, 1)[0];
        this.yesterdayTotal = data
          .map((d) => d.total)
          .reverse()
          .slice(1, 2)[0];
      } else {
        this.chartData = data
          .map((d) => d.total)
          .slice(0, this.days)
          .reverse();
        this.chartLabels = data
          .map((d) => new Date(d.documentDate).toLocaleDateString())
          .slice(0, this.days)
          .reverse();
        this.todayTotal = data.map((d) => d.total).slice(0, 1)[0];
        this.yesterdayTotal = data.map((d) => d.total).slice(1, 2)[0];
      }
    },
    locationPercentage(item) {
      return (item.total / this.totalAllLocations) * 100;
    },
    groupData(data) {
      let result = data.reduce((acc, item) => {
        const { month, total } = item;
        if (acc[month]) {
          acc[month].total += total;
        } else {
          acc[month] = {
            month,
            total,
          };
        }
        return acc;
      }, {});

      return Object.values(result);
    },
  },
  async created() {
    [
      this.totalPCG,
      this.topPCG,
      this.totalByDate,
      this.totalByLocations,
      this.totalStockValue,
    ] = await Promise.all([
      productService.getTotalPCG(),
      saleService.getTopPCG(),
      saleService.getTotalByDateAndVendor({ days: this.daysFromStartOfYear }),
      saleService.getTotalByLocation(),
      saleService.getTotalStockValue(),
    ]);
    this.updateWidgetData(this.totalPCG);
    this.totalValue = this.totalByDate.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total,
      0
    );
    this.getChartProps(this.totalByDate, false);
    this.totalAllLocations = this.totalByLocations.reduce(
      (total, item) => total + item.total,
      0
    );
    this.loading = false;
  },
};
</script>
