<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("locations.allLocations") }}</h2>
        <p class="mb-0">{{ $t("locations.allLocationDetail") }}</p>
      </div>
    </div>
    <data-table :callback="getLocationsData" ajax :columns="columns" :actions="actions"></data-table>
  </div>
</template>

<script>
import { locationService } from '../services/location.service.js';

export default {
  data() {
    return {
      columns: [
        { name: 'code', th: this.$t('locations.code') },
        { name: 'name', th: this.$t('locations.name') },
      ],
      actions: [
        {
          text: this.$t('locations.viewStocks'),
          color: 'success',
          size: 'sm',
          action: (row) => {
            this.showStock(row.code);
          },
        },
      ],
    };
  },
  methods: {
    showStock(locationCode) {
      this.$router.push({ path: `locations/${locationCode}/stocks` });
    },
    async getLocationsData(params) {
      return await locationService.getAllLocations(params);
    },
  },
};
</script>
