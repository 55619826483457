<script>
import { Pie, mixins } from "vue-chartjs";

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.chartLabels,
          datasets: [
            {
              label: "Vlastito ucesce po kategorijama",
              data: this.chartData,
              backgroundColor: [
                "rgb(6, 72, 179)", // Primary
                "rgb(238, 80, 80)", // Secondary
                "rgb(5, 166, 119)", // Tertiary
                "rgb(35, 105, 202)", // Lightened Primary
                "rgb(247, 131, 131)", // Lightened Secondary
                "rgb(32, 191, 147)", // Lightened Tertiary
                "rgb(3, 55, 137)", // Darkened Primary
                "rgb(202, 48, 48)", // Darkened Secondary
                "rgb(3, 124, 91)", // Darkened Tertiary
                "rgb(53, 129, 219)", // Lighter Blue (Primary)
                "rgb(251, 158, 158)", // Lighter Red (Secondary)
                "rgb(63, 209, 171)", // Lighter Green (Tertiary)
                "rgb(12, 92, 171)", // Muted Primary
                "rgb(227, 98, 98)", // Muted Secondary
                "rgb(21, 150, 109)", // Muted Tertiary
              ],
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
        }
      );
    },
  },
  mounted() {
    this.renderLineChart();
  },

  watch: {
    chartData() {
      this.renderLineChart();
    },
  },
};
</script>
