import axios from 'axios';
import store from '../store/index.js';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(function(config) {
  let authKey = store.getters.authKey;
  config.headers['Authorization'] = 'Bearer ' + authKey;
  return config;
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error.response) {
      return Promise.reject(error);
    } else {
      const code = error.response.status;
      const errorData = error.response.data;
      const originalRequest = error.config;

      if (originalRequest.url !== "/auth/signin" && error.response) {
        // Access token has expired
        if (code === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            await store.dispatch('refreshToken', {
              accessToken: store.getters.authKey,
              refreshToken: store.getters.refreshToken
            });

            return apiClient(originalRequest);
          } catch(_error) {
            store.dispatch('logout');
            window.location.href = '/auth/login';
          }
        }
      }

      if (code === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        store.dispatch('logout');
        window.location.href = '/auth/login';
      }

      if (code >= 500) {
        return Promise.reject(error);
      }

      return Promise.resolve(errorData);
    }
  }
);

export default apiClient;
