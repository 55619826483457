import Vue from 'vue';
import Vuex from 'vuex';

import authModule from './auth.js';
import { Role } from '../helpers/role.js';

import i18n from '../services/i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      menuOpened: false,
      menuItems: {
        sectionOne: [
          {
            id: '1-one',
            title: i18n.t('nav.overview'),
            icon: 'chart-pie',
            link: '/admin/dashboard',
            role: [],
          },
        ],
        sectionTwo: [
          {
            id: '1-two',
            title: i18n.t('nav.users'),
            icon: 'user',
            link: '/admin/users',
            role: [Role.Admin],
          },
          {
            id: '2-two',
            title: i18n.t('nav.vendors'),
            icon: 'building',
            link: '/admin/vendors',
            role: [Role.Admin],
          },
          {
            id: '3-two',
            title: i18n.t('nav.products'),
            icon: 'cubes',
            link: '/admin/products',
            role: [Role.Admin, Role.User],
          },
          {
            id: '4-two',
            title: i18n.t('nav.categories'),
            icon: 'list',
            link: '/admin/categories',
            role: [Role.Admin, Role.User],
          },
          {
            id: '5-two',
            title: i18n.t('nav.groups'),
            icon: 'layer-group',
            link: '/admin/groups',
            role: [Role.Admin, Role.User],
          },
          {
            id: '6-two',
            title: i18n.t('nav.locations'),
            icon: 'map-marked',
            link: '/admin/locations',
            role: [Role.Admin, Role.User],
          },
        ],
        sectionThree: [
          {
            id: '1-three',
            title: i18n.t('nav.saleParticipation'),
            icon: 'poll',
            link: '/admin/reports/sale-participation',
            role: [Role.Admin, Role.User],
          },
          {
            id: '2-three',
            title: i18n.t('nav.salePerCategory'),
            icon: 'poll',
            link: '/admin/reports/sale-category',
            role: [Role.Admin, Role.User],
          },
          {
            id: '3-three',
            title: i18n.t('nav.salePerGroup'),
            icon: 'poll',
            link: '/admin/reports/sale-group',
            role: [Role.Admin, Role.User],
          }
        ]
      },
    };
  },
  mutations: {
    updateMenuState(state) {
      state.menuOpened = !state.menuOpened;
    },
  },
  actions: {
    toggleMenu(context) {
      context.commit('updateMenuState');
    },
  },
  getters: {
    menuItems(state) {
      return state.menuItems;
    },
    menuOpen(state) {
      return state.menuOpened;
    },
  },
  modules: {
    auth: authModule,
  },
});
