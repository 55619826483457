<template>
  <div>
    <base-modal :showModal="showProductSale" title="" previewMode size="modal-xl" @close="showProductModal(null)">
      <template #default>
        <h2 class="h5 mb-4">{{ $t("salePerGroup.productSaleTitle") }}</h2>
        <div class="table-responsive">
        <table class="table table-centered table-nowrap mb-0 rounded">
          <thead class="thead-light">
            <tr>
              <th class="border-0">{{ $t("salePerGroup.table.code") }}</th>
              <th class="border-0">{{ $t("salePerGroup.table.product") }}</th>
              <th class="border-0">{{ $t("salePerGroup.table.location") }}</th>
              <th class="border-0" @click="modalSortList('quantity')">{{ $t("salePerGroup.table.quantity") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="modalSortList('total')">{{ $t("salePerGroup.table.total") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="modalSortList('stock')">{{ $t("salePerGroup.table.stock") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="modalSortList('stockTotal')">{{ $t("salePerGroup.table.stockTotal") }} <i class="fas fa-sort"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in productSales" :key="[item.productCode, item.locationName].toString()">
              <td>{{ item.productCode }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.locationName }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.total | toCurrency }}</td>
              <td>{{ item.stock }}</td>
              <td>{{ item.stockTotal | toCurrency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </template>
    </base-modal>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        py-4
      "
    >
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("salePerGroup.report") }}</h2>
        <p class="mb-0">{{ reportName }}</p>
      </div>
    </div>
    <div class="card card-body border-0 shadow mb-4">
      <form id="filterReport" @submit.prevent="getReport(formData)">
        <div
          class="
            row
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
          "
        >
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="location">{{ $t("salePerGroup.location") }}</label>
              <select
                name="location"
                id="location"
                class="form-select"
                v-model="formData.location"
                required
              >
                <option
                  v-for="location in locations.data"
                  :key="location.code"
                  :value="location.code"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="group">{{ $t("salePerGroup.group") }}</label>
              <select
                name="group"
                id="group"
                class="form-select"
                v-model="formData.group"
                required
              >
                <option
                  v-for="group in groups.data"
                  :key="group.code"
                  :value="group.code"
                >
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2 mb-3">
            <div class="form-group">
              <label for="from-date">{{ $t("salePerGroup.fromDate") }}</label>

              <input
                data-datepicker=""
                class="form-control datepicker-input"
                id="from-date"
                type="date"
                min="2021-01-01"
                placeholder="dd/mm/yyyy"
                required
                v-model="formData.fromDate"
              />
            </div>
          </div>
          <div class="col-md-2 mb-3">
            <div class="form-group">
              <label for="to-date">{{ $t("salePerGroup.toDate") }}</label>

              <input
                data-datepicker=""
                class="form-control datepicker-input"
                id="to-date"
                type="date"
                min="2021-01-01"
                placeholder="dd/mm/yyyy"
                required
                v-model="formData.toDate"
              />
            </div>
          </div>
          <div class="col-md-1 mb-3">
            <div class="form-group mt-4">
              <button
                class="btn btn-primary d-inline-flex align-items-center"
                type="button"
                @click="getReport(formData)"
                :disabled="!validInput"
              >
                <span class="fas fa-search mr-2"></span>{{ $t("salePerGroup.generate") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card card-body border-0 shadow mb-4" v-else>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap mb-0 rounded">
          <thead class="thead-light">
            <tr>
              <th class="border-0">{{ $t("salePerGroup.table.code") }}</th>
              <th class="border-0">{{ $t("salePerGroup.table.product") }}</th>
              <th class="border-0">{{ $t("salePerGroup.table.location") }}</th>
              <th class="border-0" @click="sortList('quantityProcured')">{{ $t("salePerGroup.table.quantityProcured") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('totalProcured')">{{ $t("salePerGroup.table.totalProcured") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('quantity')">{{ $t("salePerGroup.table.quantity") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('total')">{{ $t("salePerGroup.table.total") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('stock')">{{ $t("salePerGroup.table.stock") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('stockTotal')">{{ $t("salePerGroup.table.stockTotal") }} <i class="fas fa-sort"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr :class="{ 'table-active': (item.productCode + item.locationName == selectedRow) }" v-for="item in sales" :key="[item.productCode, item.locationName].toString()" @click="selectRow(item.productCode + item.locationName)">
              <td @click="showProductModal(item.productCode)"><a class="text-success me-3">{{ item.productCode }}</a></td>
              <td @click="showProductModal(item.productCode)"><a class="text-success me-3">{{ item.productName }}</a></td>
              <td>{{ item.locationName }}</td>
              <td>{{ item.quantityProcured }}</td>
              <td>{{ item.totalProcured | toCurrency }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.total | toCurrency }}</td>
              <td>{{ item.stock }}</td>
              <td>{{ item.stockTotal | toCurrency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locationService } from "../../services/location.service.js";
import { productService } from "../../services/product.service.js";
import { saleService } from "../../services/sale.service.js";

export default {
  data() {
    return {
      reportId: null,
      reportName: this.$t('salePerGroup.reportName'),
      formData: {
        fromDate: new Date().toISOString().slice(0, 10),
        toDate: new Date().toISOString().slice(0, 10),
      },
      locations: [],
      groups: [],
      sales: [],
      productSales: [],
      showProductSale: false,
      sortedbyASC: false,
      loading: false,
      selectedRow: '',
    };
  },
  computed: {
    validInput() {
      return (
        this.formData.fromDate !== undefined &&
        this.formData.toDate !== undefined &&
        this.formData.location !== undefined &&
        this.formData.group !== undefined
      );
    },
  },
  methods: {
    showProductModal(code) {
      if (!this.showProductSale && code !== null) {
        this.productSales = this.getProductSalePerLocation(code);
      }
      this.showProductSale = !this.showProductSale;
    },
    selectRow(row){
      this.selectedRow = row;
    },
    modalSortList(sortBy) {
      this.sortedbyASC = !this.sortedbyASC;
      if (this.sortedbyASC) { 
        this.productSales = this.productSales.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
      } else {
        this.productSales = this.productSales.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
      }
    },
    sortList(sortBy) {
      this.sortedbyASC = !this.sortedbyASC;
      if (this.sortedbyASC) { 
        this.sales = this.sales.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
      } else {
        this.sales = this.sales.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
      }
    },
    async getReport(data) {
      this.loading = true;
      let fromDate = new Date(data.fromDate);
      let toDate = new Date(data.toDate);

      let params = {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        location: data.location,
        productGroup: data.group,
      };

      let resp = await saleService.getSalePerGroup(params);

      this.sales = resp.data;
      this.loading = false;
    },
    async getProductSalePerLocation(productCode) {
      let fromDate = new Date(this.formData.fromDate);
      let toDate = new Date(this.formData.toDate);

      let params = {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        productCode: productCode,
      };

      let resp = await saleService.getProductSalePerLocation(params);
      this.productSales = resp.data;
    },
  },
  async created() {
    [this.groups, this.locations] = await Promise.all([
      productService.getAllGroups({ pageIndex: 0, pageSize: 1000 }),
      locationService.getAllLocations(),
    ]);
    this.locations.data.unshift({"name": "---", "code": null})
    this.groups.data.unshift({"name": "---", "code": null})
  },
};
</script>