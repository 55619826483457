<template>
  <div class="data-table">
    <div class="table-settings mb-4" v-if="header">
      <div class="row align-items-center justify-content-between">
        <div class="col col-md-6 col-lg-3 col-xl-4" v-if="searchable">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon2"><span class="fas fa-search"></span></span>
            <input type="text" class="form-control" id="exampleInputIconLeft" :placeholder="searchPlacehodler" aria-label="Search" aria-describedby="basic-addon2" @keyup="search(query)" v-model="query" />
          </div>
        </div>
        <div class="col-4 col-md-2 col-xl-4 pl-md-0 text-right" v-if="limitable">
          <label>
            {{ $t("global.show") }}
            <select class="custom-select custom-select-sm" v-model="itemsPerPage">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            {{ $t("global.rows") }}
          </label>
        </div>
      </div>
      <div class="col-auto ml-auto" v-if="showFilters">
        Filters:
        <div class="table-filters d-inline-block">
          <div class="table-filter" v-for="option in filters" :key="option" @click="filter(option)">
            <span>{{ option.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="loading || ajaxLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card border-light shadow-sm mb-4" v-else>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap mb-0 rounded" :class="{ straight: !breakWords, 'table-hover': !!onClick }">
            <thead class="thead-light">
              <tr>
                <!-- Display Checkboxes If Requested -->
                <th class="border-0" v-if="selectable">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" @change="selectAll" />
                    <span class="custom-control-label"></span>
                  </label>
                </th>

                <!-- Display Index If Requested -->
                <th
                  v-if="index"
                  @click="sortIndex()"
                  class="sortable border-0"
                  :class="{
                    sort: sortColumn == '#',
                    asc: sortColumn == '#' && asc,
                    desc: sortColumn == '#' && !asc,
                  }"
                >
                  #
                </th>
                <!-- Display All Parsed Headers -->
                <th
                  :key="index"
                  v-for="(th, index) in headers"
                  @click="sort(th.name)"
                  class="sortable border-0"
                  :class="{
                    sort: sortColumn == th.name,
                    asc: sortColumn == th.name && asc,
                    desc: sortColumn == th.name && !asc,
                  }"
                >
                  {{ th.th }}
                </th>
                <!-- Display Actions If Provided -->
                <th class="border-0" v-if="actions.length">{{ $t("global.actions") }}</th>
              </tr>
            </thead>
            <tbody v-if="paginatedItems.length">
              <!-- Loop Through All Parsed and Paginated Items -->
              <tr :key="i" v-for="(item, i) in paginatedItems" :class="{ clickable: !!onClick }">
                <!-- Display Checkboxes If Requested -->
                <th v-if="selectable">
                  <div class="custom-control custom-checkbox" @click="select(item)">
                    <input type="checkbox" class="custom-control-input" :checked="item.selected" />
                    <span class="custom-control-label"></span>
                  </div>
                </th>

                <!-- Display Index If Requested -->
                <td v-if="index">{{ item.index + 1 }}</td>

                <!-- Display All Parsed Values -->
                <td :key="j" v-for="(td, j) in item.details" @click="click(item.row, td.value, td.name, i), columnClick(td.click, item.row, td.value, td.name, i)">
                  <!-- <component :is="i+'Component'" v-if="value.render"></component> -->
                  <span v-html="td.rendered != null ? td.rendered : '----'"></span>
                </td>

                <!-- Diplay Actions If Provided -->
                <td v-if="item.buttons.length">
                  <!-- Loop Through All Provided Actions -->
                  <button
                    type="button"
                    class="btn mr-3"
                    :class="`btn-${button.color} btn-${button.size}`"
                    v-for="(button, j) in item.buttons"
                    :key="j"
                    @click="button.action(item.row, item.index)"
                    :disabled="button.disabled"
                  >
                    {{ button.text }}
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <!-- Display Empty Message If No Items Are Rendered -->
              <tr>
                <td align="center" :colspan="headers.length + (actions.length ? 1 : 0) + (index ? 1 : 0)">
                  No results
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer px-3 border-0 d-flex align-items-center justify-content-between" v-if="footer">
        <nav aria-label="Page navigation" v-if="paginate">
          <ul class="pagination mb-0" v-if="paginateLinks.length">
            <li class="page-item" v-if="pages && currentPage != 1">
              <a class="page-link" @click="prev">{{ $t("global.previous") }}</a>
            </li>
            <li class="page-item" v-bind:key="item.page" v-for="item in paginateLinks" :class="{ active: currentPage == item.page }">
              <span class="page-link" @click="paginate(item.page)">{{ item.page }}</span>
            </li>

            <li class="page-item" v-if="pages && currentPage < pages">
              <a class="page-link" @click="next">{{ $t("global.next") }}</a>
            </li>
          </ul>
        </nav>
        <div class="font-weight-bold small" v-if="pageDetails">
          {{ $t("global.pagShowing") }}
          <!-- Current Page Starting Index -->
          {{ paginatedItems.length ? itemsPerPage * (currentPage - 1) + 1 : 0 }}
          {{ $t("global.pagTo") }}
          <!-- Current Page End Index -->
          {{ itemsPerPage * (currentPage - 1) + paginatedItems.length }}
          {{ $t("global.pagOf") }}
          <!-- All Items Provided -->
          {{ dataSize }} {{ $t("global.items") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Items TO Display For Each Paginated Page
      itemsPerPage: 25,
      // Current Page Number In Pagination
      currentPage: 1,
      // Current Page Items
      paginatedItems: [],
      // Sort Order
      asc: 'asc',
      // Column For Sorting
      sortColumn: null,
      // Search Query
      query: '',
      // Table Headers
      headers: [],
      // Mapped Data
      items: [],
      // Mapped Action Buttons
      buttons: [],
      // Loading State For Ajax Requests
      ajaxLoading: false,
      // Items To Be Displayed
      renderedItems: [],
      // Total items
      dataSize: 0,
      // Selected Items
      selected: [],
      showItemsPerPage: false,
      searchPlacehodler: this.$t('global.search'),
      timeout: null,
    };
  },
  props: {
    // =================================
    // Ajax
    // Datatables Ajax Uses Axios
    // Make Sure Axios Is Added As An NPM Dependency
    // =================================
    // Ajax URL
    callback: {
      type: Function,
    },
    // Whether Or Not To Use Ajax
    ajax: {
      type: Boolean,
      default: () => false,
    },

    // Table Items
    data: {
      type: Array,
      default: () => [],
    },
    // Action Buttons For Each Item
    actions: {
      type: Array,
      default: () => [],
    },
    // Columns and Appropriate Data Assigment
    columns: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    // Whether or Not Items Should Be Indexed
    index: {
      type: Boolean,
      default: () => true,
    },
    // Set Loading Status
    loading: {
      type: Boolean,
      default: () => false,
    },
    // Click Events For Each Cell
    onClick: {
      type: Function,
      default: () => {},
    },
    // Whether Or Not The Table Should Be Allowed To Break Elements
    breakWords: {
      type: Boolean,
      default: () => false,
    },
    // Whether Or Not The Header Should Be Visible
    header: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not The Footer Should Be Visible
    footer: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Searching Should Be Available
    searchable: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Page Limitation Should Be Changeable
    limitable: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Details Should Be Visible
    pageDetails: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not The Results Should Be Paginatable
    paginatable: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Items Should Be Selctable
    selectable: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    // Navigate To Provided Page
    // Arguments
    // 	Page: int
    paginate(page) {
      this.currentPage = page;
    },
    // Navigate To Next Page
    next() {
      this.currentPage = this.currentPage >= this.renderedItems.length ? 0 : this.currentPage + 1;
    },
    // Navigate To Previous Page
    prev() {
      this.currentPage = this.currentPage <= 0 ? this.renderedItems.length : this.currentPage - 1;
    },
    // Navigate To Last Page
    end() {
      this.currentPage = this.renderedItems.length;
    },
    // Navigate To First Page
    start() {
      this.currentPage = 1;
    },
    // Search Through Items With Provided Search Query
    // Arguments
    // 	Query: string
    async search(query) {
      // clear timeout variable
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(async () => {
          // enter this block of code after 1 second
          // handle stuff, call search API etc.
          await this.reloadDataOnPagginationChange(query, 0, this.itemsPerPage);
          this.sortIndex(true);
      }, 1000);
    },
    // Sort Items By Specified Column and Order
    // Arguments
    // 	Column: String
    // 	Order: String [asc, desc]
    // sort(column) {
    //   this.renderedItems = this.renderedItems.sort((a, b) => {
    //     var detailx = a.details.find((detail) => detail.name == column);
    //     var x = detailx.rendered;
    //     x = typeof x == 'string' ? x.toLowerCase() : x;
    //     var detaily = b.details.find((detail) => detail.name == column);
    //     var y = detaily.rendered;
    //     y = typeof y == 'string' ? y.toLowerCase() : y;
    //     return x > y ? 1 : -1;
    //   });
    //   if (column !== this.sortColumn) {
    //     this.asc = true;
    //   } else {
    //     this.asc = !this.asc;
    //   }
    //   if (!this.asc) {
    //     this.renderedItems = this.renderedItems.reverse();
    //   }
    //   this.sortColumn = column;
    //   this.currentPage = 1;
    // },
    sortIndex(asc) {
      this.renderedItems = this.renderedItems.sort((a, b) => {
        var indexA = a.index;
        var indexB = b.index;
        return indexA > indexB ? 1 : -1;
      });
      this.asc = this.sortColumn == '#' ? !this.asc : true;
      if (asc != undefined) {
        if (!asc) {
          // console.log(this.renderedItems);
          this.renderedItems = this.renderedItems.reverse();
        }
      } else {
        if (!this.asc) {
          this.renderedItems = this.renderedItems.reverse();
        }
      }

      this.sortColumn = '#';
      this.currentPage = 1;
    },
    // filter(filter) {
    //   var filterValue = filter.value,
    //     filterColumn = filter.name;
    //   var items = this.mapItems(this.items);
    //   var filtered = items.filter((item, index) => {
    //     var column = item.details.find((column) => column.name == filterColumn);
    //     if (!column) {
    //       return false;
    //     }
    //     // If Value Type Is A Custom Function
    //     if (filterValue.constructor.toString().match(/Function/)) {
    //       if (filterValue(item.row, column.value, index)) {
    //         return true;
    //       }
    //     } else if (column.value == filterValue || column.rendered == filterValue) {
    //       return true;
    //     }
    //     return false;
    //   });
    //   this.renderedItems = filtered;
    //   this.currentPage = 1;
    //   this.sortIndex(false);
    // },
    getHeaders() {
      this.headers = this.columns
        .filter((item) => item.show !== false)
        .map((item) => ({
          name: item.name,
          th: item.th,
          show: true,
        }));
    },
    mapItems(items) {
      items = items.map((item, index) => {
        // Row Item
        var row = {
          row: item,
          details: [],
          index: index + (this.currentPage - 1) * this.itemsPerPage,
          buttons: [],
          selected: !!this.selected.find((a) => a.index == index),
        };
        // Get Provided Columns
        this.columns
          .filter((column) => column.show !== false)
          .forEach((column) => {
            row.details.push({
              // Item Column Name
              name: column.name,
              // Table Header Title
              th: column.th,
              // Provided Value
              value: item[column.name],
              // Decide Value Depending On Whether Render Method Is Provided
              rendered: column.render ? column.render(item, item[column.name], index) : item[column.name],
              // Origin Item Row
              row: item,
              // Whether Or Not To Display Item
              show: true,
              // Click Event For Column
              click: column.click,
            });
          });
        // Get Provided Actions
        this.actions.forEach((button) => {
          row.buttons.push({
            // Spread Provided Button Properties
            ...button,
            // Decide Visibility Depending On Whether Show Method Is Provided
            // Default: true
            show: button.show ? button.show(item, index) : true,
            disabled: button.disabled ? button.disabled(item, index) : false,
          });
        });
        return row;
      });
      return items;
    },
    click() {
      if (this.onClick) {
        this.onClick(...arguments);
      }
    },
    columnClick(action, row, cell, name, index) {
      if (action) {
        action(row, cell, name, index);
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selected = [];
        this.renderedItems.forEach((item) => {
          item.selected = true;
          this.selected.push(item);
        });
      } else {
        this.selected = [];
        this.renderedItems.forEach((item) => {
          item.selected = false;
        });
      }
    },
    select(item) {
      var index = this.selected.findIndex((a) => a.index == item.index);
      if (index > -1) {
        item.selected = false;
        this.selected.splice(index, 1);
      } else {
        item.selected = true;
        this.selected.push(item);
      }
    },
    toggleItemPerPage() {
      this.showItemsPerPage = !this.showItemsPerPage;
    },
    async reloadDataOnPagginationChange(searchQuery, pageIndex, pageSize) {
      this.ajaxLoading = true;
      let response = await this.callback({ searchQuery, pageIndex, pageSize });
      this.dataSize = response.count;
      this.items = response.data;
      this.ajaxLoading = false;
    },
  },
  computed: {
    // Total Number Of Pages For Pagination
    pages() {
      if (this.dataSize > this.itemsPerPage) {
        return Math.ceil(this.dataSize / this.itemsPerPage);
      } else {
        return 0;
      }
    },
    // Array Of Links With Page Number For Pagination
    paginateLinks() {
      var links = [];
      let center = Math.round(this.pages / 2) - 1;
      for (var i = 0; i < this.pages; i++) {
        if (this.pages > 6) {
          let difference = this.currentPage - i;
          // around the current page
          if (!(difference < 0) && !(difference > 2)) {
            // around the center
          } else if (i === center) {
            // at the start or end
          } else if (this.pages - i <= 2 || i <= 1) {
            // everywhere else
          } else {
            continue;
          }
        }
        links.push({ page: i + 1 });
      }
      return links;
    },
    showFilters() {
      return Object.keys(this.filters).length > 0;
    },
  },
  watch: {
    async currentPage(newValue) {
      await this.reloadDataOnPagginationChange(this.query, (newValue - 1), this.itemsPerPage);
      this.paginatedItems = this.renderedItems;
    },
    async itemsPerPage(newValue) {
      this.currentPage = 1;
      await this.reloadDataOnPagginationChange(this.query, (this.currentPage - 1), newValue);
      this.paginatedItems = this.renderedItems;
    },
    items(newValue) {
      this.getHeaders();
      this.renderedItems = this.mapItems(newValue);
      // Get All Items In Current Page
      this.paginatedItems = this.renderedItems;
      this.asc = true;
      //this.sortIndex();
    },
    data(newValue) {
      this.items = newValue;
    },
    renderedItems(newValue) {
      this.paginatedItems = newValue;
    },
  },
  // Lifetime Events
  async mounted() {
    // Parse Headers
    this.getHeaders();
    // Set Default Sorting To Index
    // Asc will be converted to false so order will be in reverse
    this.asc = true;
    this.sortIndex();
    // Use Provided Data If Ajax Is Not Specified
    if (!this.ajax) {
      // Map Items From Provided Data
      this.items = this.data;
      // Get All Items In Current Page
      // this.paginatedItems = this.renderedItems.slice(this.itemsPerPage * (this.currentPage - 1), (this.itemsPerPage * this.currentPage));
    } else {
      // Get Data From Server Using Ajax
      await this.reloadDataOnPagginationChange(this.query, (this.currentPage - 1), this.itemsPerPage);
    }
  },
};
</script>
