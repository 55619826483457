import apiClient from './api-client.js';

export const saleService = {
  getTotalByDateAndVendor,
  getTopPCG,
  getSaleParticipation,
  getSalePerCategory,
  getSalePerGroup,
  getTotalByLocation,
  getProductSalePerLocation,
  getTotalStockValue,
};

async function getTotalByDateAndVendor(params) {
  let response = await apiClient.get('home/total-days', { params });

  if (response.status !== 200) {
    throw Error('Unable to get sale data!');
  }

  return response.data;
}

async function getTopPCG() {
  let response = await apiClient.get('home/top-pcg');

  if (response.status !== 200) {
    throw Error('Unable to get top pcg data!');
  }

  return response.data;
}

async function getTotalByLocation() {
  let response = await apiClient.get('home/total-location');

  if (response.status !== 200) {
    throw Error('Unable to get top pcg data!');
  }

  return response.data;
}

async function getSaleParticipation(params) {
  let response = await apiClient.get('reports/sale-participation', { params });

  if (response.status !== 200) {
    throw Error('Unable to get sales per category data!');
  }

  return response;
}

async function getSalePerCategory(params) {
  let response = await apiClient.get('reports/sale-category', { params });

  if (response.status !== 200) {
    throw Error('Unable to get sales per category data!');
  }

  return response;
}

async function getSalePerGroup(params) {
  let response = await apiClient.get('reports/sale-group', { params });

  if (response.status !== 200) {
    throw Error('Unable to get sales per group data!');
  }

  return response;
}

async function getProductSalePerLocation(params) {
  let response = await apiClient.get('reports/sale-location', { params });

  if (response.status !== 200) {
    throw Error('Unable to get sales per location data!');
  }

  return response;
}

async function getTotalStockValue() {
  let response = await apiClient.get('home/total-stock-value');

  if (response.status !== 200) {
    throw Error('Unable to get total stock value!');
  }

  return response;
}
