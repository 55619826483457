import apiClient from '../services/api-client.js';

export default {
  state() {
    return {
      user: null,
      token: null,
      refreshToken: null,
      tokenExpiration: null,
    };
  },
  mutations: {
    setAuthData(state, payload) {
      state.token = payload.token;
      state.tokenExpiration = payload.tokenExpiration;
      state.refreshToken = payload.refreshToken;
      localStorage.setItem('authToken', payload.token);
      localStorage.setItem('refreshToken', payload.refreshToken);
    },
    setUserData(state, payload) {
      state.user = payload;
      if (payload != null || payload != undefined) {
        payload.password = null;
      }
      localStorage.setItem('currentUser', JSON.stringify(payload));
    },
  },
  actions: {
    async singIn(context, payload) {
      let response = await apiClient.post('auth/signin', payload);

      if (response.status !== 200) {
        throw Error(response.errors[0].description || 'Unable to login!');
      }

      context.commit('setAuthData', {
        token: response.data.accessToken.token,
        tokenExpiration: response.data.accessToken.expiresIn,
        refreshToken: response.data.refreshToken,
      });

      await context.dispatch('getUserData');
    },

    async getUserData(context) {
      let response = await apiClient.get('account/identity');

      if (response.status !== 200) {
        throw Error('Unable to get user data!');
      }

      context.commit('setUserData', response.data);
    },

    logout(context) {
      context.commit('setAuthData', {
        token: null,
        tokenExpiration: null,
        refreshToken: null,
      });
      context.commit('setUserData', null);
      localStorage.removeItem('currentUser');
    },

    async refreshToken(context, payload) {
      let response = await apiClient.post('auth/refresh', payload);

      if (response.status !== 200) {
        throw Error(response.errors[0].description || 'Unable to login!');
      }

      context.commit('setAuthData', {
        token: response.data.accessToken,
        tokenExpiration: 15,
        refreshToken: response.data.refreshToken,
      });

      await context.dispatch('getUserData');
    }
  },
  getters: {
    authKey(state) {
      if (!state.token) {
        state.token = localStorage.getItem('authToken');
        return state.token;
      }
      return state.token;
    },
    getRefreshToken(state) {
      if (!state.refreshToken) {
        state.refreshToken = localStorage.getItem('refreshToken');
        return state.refreshToken;
      }
      return state.refreshToken;
    },
    getUser(state) {
      if (!state.user) {
        state.user = JSON.parse(localStorage.getItem('currentUser'));
        return state.user;
      }
      return state.user;
    },
    isAuth(state) {
      if (!state.token) {
        state.token = localStorage.getItem('authToken');
        return !!state.token;
      }
      return !!state.token;
    },
  },
};
